<template>
  <div v-if="!$apollo.loading">
    <div class="p-2">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" fixed-width />
      </router-link>
    </div>
    <b-list-group v-if="checkIns.length">
      <b-list-group-item
        v-for="checkIn in checkIns"
        :key="checkIn.id"
        class="border-bottom m-0 rounded-0 d-flex"
        tag="a"
        :to="{ name: 'checkin-venue', params: { id: checkIn.venue.id } }"
      >
        <div class="d-flex flex-column">
          <h5>{{ checkIn.venue.name }}</h5>
          <small>{{ checkIn.createdAt.diffForHumans }}</small>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="p-2">
      <b-alert show variant="info"> Pas de visite de lieu </b-alert>
    </div>
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
  </div>
</template>
<script>
import CHECKINS from "@/graphql/auth/checkIns.gql";
export default {
  apollo: {
    checkIns: {
      query: CHECKINS,
      client: "auth",
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
.router-view {
  top: 0;
  left: 0;
  z-index: 15;
}
</style>
